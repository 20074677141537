export default function IconcWPBlanket() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="36" viewBox="0 0 32 36" fill="none">
            <g clipPath="url(#clip0_516_996)">
                <path
                    d="M8.1804 24.3369L7.77897 24.5749L8.1804 24.3369ZM15.5148 22.072L15.9461 21.894L15.5148 22.072ZM12.8598 24.5892C13.0875 24.7099 13.37 24.6232 13.4907 24.3955C13.6114 24.1677 13.5247 23.8853 13.2969 23.7646L12.8598 24.5892ZM10.966 16.2127C9.61934 16.3742 8.08104 17.1996 7.24879 18.642C6.39735 20.1177 6.33692 22.1425 7.77897 24.5749L8.58182 24.0989C7.27136 21.8885 7.41046 20.2294 8.0572 19.1085C8.72313 17.9544 9.98166 17.2708 11.0771 17.1393L10.966 16.2127ZM7.77897 24.5749C9.56272 27.5836 12.2295 28.7587 14.2341 28.1614C15.2455 27.8601 16.037 27.1116 16.3816 26.006C16.7221 24.9134 16.6153 23.5154 15.9461 21.894L15.0834 22.2501C15.6983 23.7399 15.7468 24.9061 15.4905 25.7283C15.2383 26.5375 14.6807 27.0545 13.9676 27.267C12.5232 27.6974 10.2387 26.8937 8.58182 24.0989L7.77897 24.5749ZM15.9461 21.894C15.3056 20.342 14.401 19.4243 13.444 19.0386C12.4862 18.6525 11.5156 18.8212 10.8506 19.3698C10.18 19.923 9.85823 20.8342 10.1531 21.8193C10.4431 22.7881 11.3056 23.7653 12.8598 24.5892L13.2969 23.7646C11.868 23.0071 11.2396 22.1943 11.0472 21.5516C10.8598 20.9253 11.0642 20.4036 11.4446 20.0897C11.8306 19.7713 12.4359 19.6386 13.0951 19.9042C13.7551 20.1702 14.5085 20.8572 15.0834 22.2501L15.9461 21.894Z"
                    fill="black"
                />
                <path
                    d="M10.3852 16.8037L34.3757 12.7182C34.3882 12.716 34.4005 12.716 34.413 12.7185C36.4041 13.1045 40.475 14.7767 40.9764 18.4158C41.6052 22.9788 38.003 25.2816 36.411 25.4935L15.8175 29.7371C17.3112 28.6934 19.8036 25.4817 17.8233 20.9843C15.843 16.4868 12.0394 16.3232 10.3852 16.8037Z"
                    stroke="black"
                    strokeWidth="0.933333"
                />
                <path
                    d="M6.99685 24.8471C6.92519 24.5995 6.6664 24.4569 6.41883 24.5285C6.17126 24.6002 6.02866 24.859 6.10032 25.1066L6.99685 24.8471ZM6.10032 25.1066C6.4175 26.2023 7.35541 27.8834 9.11505 29.0568C10.8943 30.2433 13.4713 30.8823 16.996 29.9403L16.755 29.0386C13.4641 29.9181 11.168 29.3039 9.63286 28.2803C8.07813 27.2435 7.2604 25.7576 6.99685 24.8471L6.10032 25.1066Z"
                    fill="black"
                />
                <path d="M18.054 21.5099L40.8975 17.6192" stroke="black" strokeWidth="0.933333" />
            </g>
            <rect x="0.44" y="4.44" width="31.12" height="31.12" rx="3.56" stroke="black" strokeWidth="0.88" />
            <defs>
                <clipPath id="clip0_516_996">
                    <rect x="3" width="29" height="32" rx="2.66667" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
